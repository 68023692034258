.locationContainer {
  width: 950px;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.locationName {
  font-size: 28px;
  line-height: 30px;
  color: #606060;
  font-weight: 500;
  text-transform: uppercase;
}

.locationSensorImage {
  height: 95px;
}

.locationInfo {
  line-height: 10px;
}
