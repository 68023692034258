.guageContainer {
  height: 140px;
  background-color: #000099;
  width: 1020px;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
