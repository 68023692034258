.container {
  display: flex;
  align-items: center;
  background-color: #e9e9e9;
  flex-direction: column;
  height: 100vh;
}

.app {
  display: flex;
  width: 1120px;
  height: 95vh;
  align-items: center;
  justify-content: start;
  background-color: white;
  flex-direction: column;
}
