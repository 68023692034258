.NavigationTitle {
  color: white;
  font-weight: 600;
  text-decoration: none;
  padding: 17px 15px 15px;
  text-transform: uppercase;
  background-color: "red";
  transition: 0.15s ease-in-out;
}

.NavigationTitle:hover {
  background-color: #04173f;
}
